<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">明细</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<div class="stockView">
					<div class="stockRow" style="border-bottom: solid 1px #F2F2F2;font-size: 14px;">
						<div class="stockItem">条形码</div>
						<div class="stockItem">名称</div>
						<div class="stockItem">简码</div>
						<div class="stockItem">适用宠物</div>
						<div class="stockItem">分类</div>
						<div class="stockItem">规格</div>
						<div class="stockItem">平均成本价</div>
						<div class="stockItem">售价</div>
						<div class="stockItem">当前库存</div>
					</div>
					<div class="stockRow" style="font-size: 14px;">
						<div class="stockItem">{{editMsg.barCode}}</div>
						<div class="stockItem" style="color: #F77E04;">{{editMsg.title}}</div>
						<div class="stockItem">{{editMsg.simpleCode}}</div>
						<div class="stockItem">{{editMsg.applyType.name}}</div>
						<div class="stockItem">{{editMsg.secondCategoryInfo.name}}</div>
						<div class="stockItem" style="color: #F77E04;">{{editMsg.model}}</div>
						<div class="stockItem">{{editMsg.currentCostPrice}}</div>
						<div class="stockItem">{{editMsg.salePrice}}</div>
						<div class="stockItem">{{editMsg.currentCount}}</div>
					</div>
				</div>
				<!-- 搜索栏 -->
				<div class="searchView">
					<el-input v-model="keyWord" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
						style="width: 200px;margin-right: 8px;" @keyup.enter.native="handelSearchClick"></el-input>
					<el-button type="primary" style="margin-right: 32px;" @click="handelSearchClick">查询</el-button>
					<div class="search">
						<div class="searchTitle">出/入库类型:</div>
						<el-select v-model="operType" placeholder="请选择" style="width: 143px;" @change="handelSearchClick">
							<el-option v-for="item in operTypeOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search">
						<div class="searchTitle">出/入库时间:</div>
						<el-date-picker v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" style="width: 260px;" @change="handelSearchClick">
						</el-date-picker>
					</div>
					<div class="btnView">
						<el-button @click="handleResetClick">重置</el-button>
						<el-button v-if="$buttonAuthority('cangku-chanpinList-track-export')" @click="exportClick">导出</el-button>
					</div>
				</div>
				<!-- 表格 -->
				<div class="tableView">
					<div class="tableWarp">
						<el-table :data="tableData">
							<el-table-column fixed prop="orderType" label="出/入库类型">
							</el-table-column>
							<el-table-column prop="orderNo" label="出/入库单号">
							</el-table-column>
							<el-table-column prop="orderTime" label="出/入库时间">
							</el-table-column>
							<el-table-column prop="userName" label="出/入库人">
							</el-table-column>
							<el-table-column prop="beforeCount" label="出/入库前数量">
							</el-table-column>
							<el-table-column prop="afterCount" label="出/入库后数量">
							</el-table-column>
							<el-table-column prop="changeCount" label="盈亏数量">
							</el-table-column>
							<el-table-column label="操作" width="60" v-if="$buttonAuthority('cangku-chanpinList-track-detail')">
								<template slot-scope="scope">
									<el-button @click="handleDetailClick(scope.row)" type="text" size="small">详情</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					
					<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
				</div>
			</div>
		</div>
		<div v-if="dialogStockDetail_state">
			<dialogStockDetail @submit="submit_dialogStockDetail" :stockId="stockId" :stockType="stockType"></dialogStockDetail>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import page from "../../../components/page.vue"
	import dialogStockDetail from "../../../components/dialogStockDetail.vue"
	export default {
		components:{
			page,
			dialogStockDetail
		},
		data() {
			return {
				editMsg: JSON.parse(this.$route.query.editMsg), //跳转信息
				page:{   //分页
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				keyWord:'',
				operType:'0',  //0全部 1入库 -1出库
				operTypeOptions:[{label:'全部',value:'0'},{label:'入库',value:'1'},{label:'出库',value:'-1'}],
				dateValue:[],  //日期
				dialogStockDetail_state:false,
				stockType:0,
				stockId:''
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//导出
			exportClick(){
				axios({
						method: 'get',
						url: process.env.VUE_APP_export+'/store/goods/Track/export?goodsId='+this.editMsg.goodsId,
						responseType: 'blob',
						params:{
							keyWord:this.keyWord,
							startTime: new Date(this.dateValue[0]).getTime() / 1000||'',
							endTime: new Date(this.dateValue[1]).getTime() / 1000||'',
							operType:this.operType
						}
					})
					.then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data], {
							type: "application/ms-excel"
						}));
						const link = document.createElement('a');
						link.style.display = 'none';
						link.href = url;
						link.setAttribute('download', `明细导出.xls`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						window.URL.revokeObjectURL(url);
					})
			},
			handleDetailClick(row){
				if(row.operType == 1){  //入库
					this.stockType = 0;
				}else if(row.operType == -1){
					this.stockType = 1;
				}
				this.stockId = row.orderId;
				this.dialogStockDetail_state = true;
			},
			//关闭详情
			submit_dialogStockDetail(){
				this.dialogStockDetail_state = false;
			},
			//重置
			handleResetClick(){
				this.page.pageIndex = 1;
				this.keyWord = '';
				this.operType= '0';
				this.dateValue = [];
				this.getTableData()
			},
			handelSearchClick(){
				if(this.dateValue == null){
					this.dateValue = [];
				}
				this.page.pageIndex = 1;
				this.getTableData()
			},
			//获取数据
			getTableData(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
					keyWord:this.keyWord,
					startTime: new Date(this.dateValue[0]).getTime() / 1000,
					endTime: new Date(this.dateValue[1]).getTime() / 1000,
					operType:this.operType
				}
				this.$http.post(`/store/goods/Track/goodsId=${this.editMsg.goodsId}`, params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//切换分页
			changePage(num){
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;

		.view {
			height: 100%;
			background: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;

			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}

			.viewC {
				height: calc(100% - 108px);
				flex: 1;
				padding-top: 20px;
				display: flex;
				flex-direction: column;

				.stockView {
					border-radius: 20px;
					box-sizing: border-box;
					overflow: hidden;
					background: #F8F9F9;
					margin: 0 20px;

					.stockRow {
						display: flex;
						align-items: center;
						height: 44px;
						box-sizing: border-box;
						padding: 0 16px;

						.stockItem {
							flex: 1;
						}
					}
				}

				.searchView {
					height: 80px;
					border-radius: 20px;
					background: #fff;
					display: flex;
					align-items: center;
					padding: 0 20px;

					.search {
						display: flex;
						align-items: center;
						margin-right: 32px;

						.searchTitle {
							font-size: 14px;
							color: $fontColor;
							margin-right: 4px;
						}
					}

					.btnView {
						flex: 1;
						text-align: right;
					}
				}


				.tableView {
					height: calc(100% - 168px);
					display: flex;
					flex-direction: column;
					border-radius: 20px;
					overflow: hidden;
					.tableWarp{
						overflow-y: scroll;
						flex: 1;
						height: calc(100% - 52px);
					}
				}

				.tableWarp::-webkit-scrollbar {
					display: none;
				}
			}

		}
	}
</style>
